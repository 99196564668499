// 对应装修单个商品
import React, { useEffect, useState, useRef, useMemo } from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import { useIntl } from 'react-intl';
import { jumpToTargetPageNeedSeo } from 'utils/web-tool';
import { Wrapper } from 'components/base';
import SkuSelect from 'cpn/sku-select';
import goodsApi from 'api/goods';
import { GoodsInfoText, GoodsScoreInfoStyle, Style } from './style';
import PreviewProduct from 'cpn/preview-product';
import { SUPPORT_LANGUAGE, GOODS_FEATURE_TYPE, ECARD_GOODS_STATUS_STR } from '../../../../const';
import { ShareButtons } from 'cpn/share-buttons';
import { ContextGoodDetail } from '../../../context/context-good-detail';
import { HtGoodsDetailPrice, HtGoodsSkuPrice } from 'cpn/goods-price';
import { getVariantMediaIndex, getProductMedia } from 'helper/goods';
import NewLink from 'cpn/new-link';
import { GroupBuyingEntry } from 'components/page/group-buying/group-buying-entry';
// import ProductMediaPreview from './components/product-media-preview';
import { DiscountDesc } from 'cpn/discount';
import { buriedPoint } from 'utils/log-buried-point';
import GoodsScore from '../../components/goods-score';
import { getReviewCountStrWithZero } from 'utils/formate';
import { GoodsTitle } from 'components/page/goods-detail/goods-title';
import { getTotalAttachPropsPrice } from 'cpn/sku-list/additional-items/utils';
import { GoodsDesc } from 'components/page/goods-detail/goods-desc';

/** 下面这一行不需要多语言 */
const DefaultGoodsData = {
  title: "Your product's name",
  minPrice: 0,
};

/**
 *  获取分组数据-用于装修组件
 * */

export const fetchGoods = async ({ kdtId, goodsId }) => {
  if (!goodsId) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  const res = await goodsApi.getGoodsById({
    kdtId,
    goodsId,
  });
  return new Promise((resolve) => {
    resolve(res);
  });
};

const FeaturedProduct = (props) => {
  const { settings = {}, initialData = {}, history, elemClass, data = {} } = props;
  const { language = SUPPORT_LANGUAGE.EN, currency, kdtId } = initialData.shopSetting || {};
  const {
    enable_payment_button: enablePaymentButton,
    show_quantity_selector: showQuantitySelector,
    show_vendor: showVendor,
    featured_product: featuredProduct = {},
    box_width: boxWidth,
    show_vendor_thumbnail: showVendorThumbnail,
    thumbnail_display_direction: thumbnailDisplayDirection,
    image_ratio: imageRatio = 'auto',
    product_name_size: productNameSize,
    product_price_size: productPriceSize,
    show_sales: showSales,
    show_comment: showComment,
    show_stock,
    show_dash_discount_label: showDashDiscountLabel,
  } = settings;

  const socialShareData = {
    share_button_color: settings?.share_button_color,
    share_button_custom: settings?.share_button_custom,
    share_button_shape: settings?.share_button_shape || 'circular',
    show_share_button: settings?.show_share_buttons,
  };

  const { goodsId } = featuredProduct;
  const [goodsDetail, setGoodsDetail] = useState(data || DefaultGoodsData);
  const handle = goodsDetail?.handle;
  const [sku, setSku] = useState({});
  const media = getProductMedia(data?.media, data?.variants);
  const [goodCover, setGoodCover] = useState(data ? media || [] : DefaultGoodsData);
  const [isDeleted, setIsDeleted] = useState(false);

  const [mainMediaIndex, setMainMediaIndex] = useState(0);

  /**
   * 展示库存标签
   * 装修开启库存标签
   * 卡券商品 无限库存不展示
   * 卡券商品 过期不展示
   */
  const showStock = useMemo(() => {
    if (!show_stock) return false;
    const isECard = goodsDetail?.goodsFeatureType === GOODS_FEATURE_TYPE.eCard;
    if (isECard && goodsDetail?.unlimitedInventory === 1) return false;
    if (isECard && goodsDetail?.goodsSoldStatusStr === ECARD_GOODS_STATUS_STR.expired) return false;
    return true;
  }, [goodsDetail, show_stock]);

  const goodsDetailProviderData = {
    imageRatio: imageRatio || 'adapt',
    layoutStyle: 'style1',
    productMedia: {
      mainMediaIndex,
      mediaList: goodCover,
    },

    updateMainMediaIndex: setMainMediaIndex,
    updateProductMediaList: setGoodCover,
  };

  const ref = useRef({});

  const intl = useIntl();
  const getGoodsDetail = () => {
    goodsApi
      .getGoodsById({
        kdtId,
        goodsId,
      })
      .then((res) => {
        const media = getProductMedia(res?.media, res?.variants);
        setIsDeleted(res.isDeleted === 1);
        setGoodsDetail(res || {});
        setGoodCover(media || []);
        setMainMediaIndex(0);
        // eslint-disable-next-line
        const { goodsId: goods_id, goodsType: goods_type = '', title } = res;
        buriedPoint('goods_view', {
          goods_component_location: 'HOME_featured-products',
          goods_component_location_name: '单个商品',
          goodsList: [
            {
              goods_id,
              goods_type,
              title,
            },
          ],
        });
      });
  };

  useEffect(() => {
    if (goodsId) {
      getGoodsDetail();
    } else {
      setGoodsDetail(DefaultGoodsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodsId]);

  useEffect(() => {
    const variantMediaIndex = getVariantMediaIndex(sku, goodCover);

    if (variantMediaIndex > -1) {
      setMainMediaIndex(variantMediaIndex);
    }
  }, [sku]);

  const jumpToDetail = () => {
    if (goodsId) {
      jumpToTargetPageNeedSeo(`/products/${handle}`);
    }
  };

  const onSkuChange = (val) => {
    setSku(val);
  };

  const hasMargin = !(boxWidth === 'full_screen');
  const attachPropsPrice = getTotalAttachPropsPrice(sku.goodsAdditionalItems);

  if (isDeleted) {
    return null;
  }

  return (
    <Style className={cn('featured-products-section', elemClass, `cn-${boxWidth}`)}>
      {/* 这里用这个玩意有点问题 因为外层用了display flex */}
      <Wrapper
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        marginTop={hasMargin ? 'vContainer' : 0}
        marginBottom={hasMargin ? 'vContainer' : 0}
      >
        <div className='featured-products'>
          <ContextGoodDetail.Provider value={goodsDetailProviderData}>
            <PreviewProduct
              media={goodCover}
              goodsId={goodsId}
              handle={handle || goodsDetail.title}
              isZoom={false}
              isShowGallery={showVendorThumbnail}
              galleryDirection={thumbnailDisplayDirection}
            />
          </ContextGoodDetail.Provider>
          <div className='products-info-container'>
            <div className={cn({ 'click-able': goodsId })} onClick={jumpToDetail}>
              <GoodsTitle title={goodsDetail?.title || "Your product's name"} productNameSize={productNameSize} />
              {goodsDetail?.goodsDesc ? <GoodsDesc desc={goodsDetail?.goodsDesc} /> : <></>}
              {(showComment || (showVendor && !!goodsDetail?.vendor) || showSales || showStock) && (
                <GoodsScoreInfoStyle>
                  {showComment && (
                    <>
                      <GoodsScore
                        score={goodsDetail?.goodsReviewsInfo?.averageScore || 5}
                        scoreSize={16}
                        className='goods-score-container'
                      />
                      <GoodsInfoText>
                        {getReviewCountStrWithZero(goodsDetail?.goodsReviewsInfo?.reviewsCount || 0)}
                        &nbsp;
                        {intl.formatMessage({ id: 'reviews', defaultMessage: '评价' })}
                      </GoodsInfoText>
                    </>
                  )}
                  {!!goodsDetail?.vendor && showVendor && (
                    <GoodsInfoText>
                      <GoodsInfoText className='goods-vendor'>{goodsDetail?.vendor}</GoodsInfoText>
                    </GoodsInfoText>
                  )}
                  {showSales && (
                    <GoodsInfoText>
                      {getReviewCountStrWithZero(goodsDetail?.soldNum || 0)}&nbsp;
                      {intl.formatMessage({
                        id: '576fd3c7319145ce830919b66e4f78cc',
                        defaultMessage: '销量',
                      })}
                    </GoodsInfoText>
                  )}
                  {showStock && (
                    <GoodsInfoText>
                      {getReviewCountStrWithZero(sku?.inventory || goodsDetail?.quantity || 0)}
                      &nbsp;
                      {intl.formatMessage({
                        id: '6bc746c24a104015a0c48f148f00f607',
                        defaultMessage: '可售',
                      })}
                    </GoodsInfoText>
                  )}
                </GoodsScoreInfoStyle>
              )}
              <div className='featured-products-price'>
                {!sku?.variantId ? (
                  <HtGoodsDetailPrice
                    attachPropsPrice={attachPropsPrice}
                    goodsDetail={goodsDetail}
                    size={productPriceSize}
                  />
                ) : (
                  <HtGoodsSkuPrice
                    attachPropsPrice={attachPropsPrice}
                    size={productPriceSize}
                    price={(sku?.price || 0) + attachPropsPrice}
                    bestPriceInfo={sku?.bestPriceInfo}
                    compareAtPrice={sku?.compareAtPrice}
                    discountInfo={sku?.discountInfo}
                    memberInfo={sku?.memberInfo}
                    variantId={sku?.variantId}
                    showDashDiscountLabel={showDashDiscountLabel}
                  />
                )}
              </div>
              {!!goodsDetail?.discountInfo?.discountType && (
                <DiscountDesc
                  title={intl.formatMessage({
                    description: '促销活动',
                    id: 'ff5ba49df86548e5b17ec1d4e543ca81',
                  })}
                  className='discount-tag'
                  discountInfo={goodsDetail?.discountInfo}
                  currency={currency}
                  language={language}
                  onLink={() => {
                    if (window.isEdit) return;
                    history.push(`/discount/${goodsDetail?.discountInfo?.discountType ?? 'fix'}/goods`);
                  }}
                />
              )}
            </div>
            <div className='featured-products-btn-warp'>
              {goodsId && (
                <div className='btn-item sku-container'>
                  <SkuSelect
                    innerRef={ref}
                    className='add-shop-car'
                    history={history}
                    onSkuChange={onSkuChange}
                    showQuantitySelector={showQuantitySelector}
                    goodsDetail={goodsDetail}
                    goodsId={goodsId}
                    currency={currency}
                    enablePaymentButton={enablePaymentButton}
                  />
                </div>
              )}
            </div>
            <GroupBuyingEntry goodsId={goodsDetail?.goodsId} currency={currency} goodsDetail={goodsDetail} />

            {goodsId ? (
              <NewLink className='featured-products-detail-link' href={`/products/${handle}`}>
                {intl.formatMessage({
                  description: 'general.preview_detail',
                  id: 'general.preview_detail',
                })}
              </NewLink>
            ) : null}
            <ShareButtons
              handle={handle}
              title={goodsDetail?.title}
              socialShareData={socialShareData}
              customClass='featured-producst-share'
              goodsDetail={goodsDetail}
            />
          </div>
        </div>
      </Wrapper>
    </Style>
  );
};

FeaturedProduct.getInitialProps = async (params) => {
  const { kdtId, featured_product: featuredProduct = {} } = params;
  const { goodsId } = featuredProduct;

  return fetchGoods({
    goodsId,
    kdtId,
  });
};

export default FeaturedProduct;
