import styled from 'styled-components';
import { getSizeVw, getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .click-able {
    cursor: pointer;
  }

  .featured-products-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 500px;
  }

  .featured-products-title {
    font-size: ${(props) => props.theme.getHeaderFontSize(props.theme.h6)};
    color: var(--color_body_text);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    word-wrap: break-word;
  }

  .featured-products-vendor {
    font-size: ${(props) => props.theme.getBaseFontSize([14, 20])};
    font-weight: 400;
    line-height: 30px;
    /* margin-bottom: 8px; */
    /* margin-bottom: 8px; */
    margin: 16px 0;
    color: var(--color_text_field_border);
  }

  .featured-products-price {
    /* font-size: ${(props) => props.theme.getBaseFontSize([18, 24])};
        font-weight: 600;
        color: var(--color_sale_text);
        line-height: 36px; */
    margin-top: ${(props) => props.theme.getSpace([12])};
  }
  .featured-products-btn-warp {
    display: flex;
  }
  .btn-item {
    display: flex;
  }
  .sku-container {
    width: 100%;
  }
  .buy-now {
    display: flex;
    align-items: flex-end;
  }
  .add-shop-car {
    flex: 1;
    color: var(--color_small_button_text_border);
    border: 1px solid var(--color_small_button_text_border);
    height: 40px !important;
    line-height: 40px !important;
  }
  .discount-tag,
  .goods-sku-price {
    margin-top: ${(props) => props.theme.getBaseFontSize([18, 14])};
  }

  .featured-products-btn {
    font-size: ${(props) => props.theme.getBaseFontSize([20, 16])} !important;
    flex: 1;
    border: none;
    font-weight: 500;
    line-height: 54px;
    cursor: pointer;
    width: ${getPercentWidth(80)};
    background-color: var(--color_button);
    color: var(--color_button_text);
    position: relative;
    &[disabled]:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.65);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .featured-products-btn-active {
    background: var(--color_button);
  }

  .featured-products-detail-link {
    font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
    display: inline-block;
    margin-top: ${(props) => props.theme.getSpace([20, 40])};
    font-weight: 400;
    color: var(--color_button);
    line-height: 20px;
    cursor: pointer;
  }

  .featured-products-share-item {
    font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
    padding: 6px ${getSizeVw(12)};
    margin-right: ${(props) => props.theme.getSpace([11, getSizeVw(8)])};
    cursor: pointer;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    display: inline-block;
    border: 1px solid #b3b9c6;
  }
  .featured-producst-share {
    margin-top: ${(props) => props.theme.getBaseFontSize([20, 40])};
  }

  .featured-products-share-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: ${getSizeVw(8)};
    &.facebook-icon {
      fill: #3c5a99;
    }
    &.twitter-icon {
      fill: #35a2e0;
    }
    &.pinterest-icon {
      fill: #cc2127;
    }
  }
  .pinterest-link {
    color: var(--color_body_text);
  }

  .products-info-container {
    flex: 1;
    margin-left: 24px;
    width: 0;
  }

  @media screen and (min-width: 1080px) {
    .featured-products-img-container {
      width: ${getSizeVw(480)};
      max-width: 525px;
      text-align: center;
      margin-right: 40px;
    }
    &.cn-full_screen {
      .featured-products {
        padding-left: 80px;
        padding-right: 80px;
      }
    }
    .featured-products {
      display: flex;
      width: 100%;
      margin: 0 auto;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1281px) {
    .featured-products {
      width: 100%;
      box-sizing: border-box;
    }
    .products-info-container {
      margin-top: 16px;
      width: auto;
      margin-left: 0;
    }
    .sku-container {
      width: 100%;
    }
    .btn-item {
      flex: 1;
    }
    .featured-products-btn {
      width: 100%;
    }
    .featured-products-img-container {
      width: 100%;
    }
  }
`;

export const GoodsScoreInfoStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.theme.getBaseSize([12])};
  color: rgba(var(--color_body_text_rgb), 0.8);
  flex-wrap: wrap;
  flex-basis: 100%;
  .goods-score-container {
    margin-right: 8px;
    margin-top: 8px;
  }
`;
export const GoodsInfoText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  max-width: 100%;
  &::after {
    content: '';
    width: 1px;
    height: 12px;
    background-color: rgba(var(--color_body_text_rgb), 0.2);
    margin: auto 8px;
    display: block;
  }
  &:last-child::after {
    display: none;
  }
  .goods-vendor {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin-top: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;
